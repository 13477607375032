import platformPreview from "../assets/platform-preview.png"
import Section from "./Section"
import {ButtonLink} from "./Button"
import {Description, Title} from "./Typing"

export default function Product() {
    return (
        <Section id="product" className="flex flex-col justify-center" secondary>
            <Title className="mb-20">
                Frictionless<br/>Platform
            </Title>
            <div className="flex flex-col gap-4 lg:gap-8 2xl:flex-row">
                <img src={platformPreview} alt="Screenshot of Platform" className="lg:max-w-4xl"/>

                <div className="flex flex-col justify-between items-start gap-4 lg:gap-8 lg:max-w-4xl 2xl:max-w-lg">
                    <Description className="sm:text-start">
                        Frictionless is the solution for projects and investors facing the limitations and negative
                        effects of current fundraising methods. Our platform utilizes smart contracts for secure,
                        decentralized, and trustless transfers of assets without impacting market prices.
                    </Description>

                    <div className="w-full flex flex-col gap-4 sm:flex-row 2xl:flex-col 2xl:w-auto">
                        <ButtonLink className="text-center" disabled>
                            Launch App
                        </ButtonLink>
                    </div>
                </div>
            </div>
        </Section>
    )
}
