import clsx from "clsx"

export function Title({className, children}) {
    return (
        <p className={clsx("text-5xl text-white text-center font-bold xs:text-6xl sm:text-7xl lg:text-8xl lg:text-start", className)}>
            {children}
        </p>
    )
}


export function SubTitle({className, children}) {
    return (
        <p className={clsx("text-3xl text-white text-center font-bold xs:text-4xl sm:text-6xl lg:text-7xl lg:text-start", className)}>
            {children}
        </p>
    )
}


export function Description({className, children}) {
    return (
        <p className={clsx("text-xl text-neutral-200 text-center font-extralight lg:text-2xl lg:text-start", className)}>
            {children}
        </p>
    )
}
