import {Disclosure} from '@headlessui/react'
import {ChevronUpIcon} from '@heroicons/react/20/solid'
import Section from "./Section"
import questions from "../assets/questions.json"
import Piece from "./Piece"
import {Title} from "./Typing"

export default function Questions() {
    return (
        <Section id="faq" secondary>
            <Title className="mb-20">
                FAQ
            </Title>

            <div className="w-full max-w-2xl flex flex-col gap-4">
                {Object.entries(questions).map(([question, answer]) => {
                    return (
                        <Disclosure key={question}>
                            {({open}) => (
                                <>
                                    <Disclosure.Button
                                        className="flex w-full justify-between rounded-lg bg-neutral-100 px-4 py-2 text-left text-lg font-medium text-neutral-800 hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                        <span>{question}</span>
                                        <ChevronUpIcon
                                            className={`${
                                                open ? 'rotate-180 transform' : ''
                                            } h-5 w-5 text-neutral-700 mt-1`}
                                        />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-4 py-2 text-lg text-neutral-300">
                                        {answer}
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    )
                })}
            </div>
            <Piece number={5} className="left-1/2 -top-24 w-[40rem] 2xl:block"/>
        </Section>
    )

}
