import {Fragment} from 'react'
import {Disclosure} from '@headlessui/react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faBars, faXmark} from "@fortawesome/free-solid-svg-icons"
import clsx from "clsx"
import frictionSVG from "../assets/fric-vector.svg"
import {Button} from "./Button"

const navigation = [
    {name: 'Home', href: '#home'},
    {name: 'Product', href: '#product'},
    {name: 'Token', href: '#token'},
    {name: 'Partner', href: '#partner'},
    {name: 'Team', href: '#team'},
    {name: 'News', href: '#news'},
    {name: 'Launch App', button: true}
]

export default function Header() {
    return (
        <Disclosure as="nav" className="absolute top-0 z-20 w-full h-32">
            {({open}) => (
                <Fragment>
                    <div className="w-full h-full flex items-center bg-neutral-975 p-8 lg:px-16 xl:px-32">
                        <div className="w-full flex justify-between items-center">
                            <div className="flex items-center gap-8">
                                <img src={frictionSVG} alt=""/>
                            </div>

                            <ul className="hidden items-center gap-12 text-neutral-500 xl:flex">
                                {navigation.map(({name, href, button}) => {
                                    return (
                                        button ? <Button key={name} disabled>
                                                Launch App
                                            </Button> :
                                            <li key={name} className="text-xl font-light hover:text-neutral-200">
                                                <a href={href}>{name}</a>
                                            </li>
                                    )
                                })}
                            </ul>

                            <Disclosure.Button className={clsx("inline-flex items-center justify-center",
                                "rounded-md p-2 text-neutral-400 hover:bg-neutral-800 hover:text-white",
                                "focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white xl:hidden")}>
                                <span className="sr-only">Open main menu</span>
                                {open ? (
                                    <FontAwesomeIcon icon={faXmark} className="text-xl" fixedWidth
                                                     aria-hidden="true"/>
                                ) : (
                                    <FontAwesomeIcon icon={faBars} className="text-xl" fixedWidth
                                                     aria-hidden="true"/>
                                )}
                            </Disclosure.Button>
                        </div>
                    </div>
                    <Disclosure.Panel className="flex justify-end xl:hidden">
                        <div className="w-full space-y-2 bg-neutral-975 px-2 pt-2 pb-3 sm:w-80">
                            {navigation.map(({name, href, button}) => (
                                <Disclosure.Button
                                    key={name}
                                    as="a"
                                    href={href}
                                    className={clsx('text-gray-300 block px-3 py-2 rounded-md text-base ' +
                                        'font-medium', button ? "bg-blue-700 opacity-50" : "hover:bg-gray-700 hover:text-white"
                                    )}
                                >
                                    {button ? <Button disabled>
                                        Launch App
                                    </Button> : name}
                                </Disclosure.Button>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </Fragment>
            )}
        </Disclosure>
    )
}
