import './App.css'
import Home from "./components/Home"
import Product from "./components/Product"
import Reason from "./components/Reason"
import WhitePaper from "./components/Whitepaper"
import Token from "./components/Token"
import Partner from "./components/Partner"
import Questions from "./components/Questions"
import Team from "./components/Team"
import News from "./components/News"
import Footer from "./components/Footer"
import Back from "./components/Back"
import Relations from "./components/Relations"

function App() {
    return (
        <div className="App relative bg-neutral-800">
            <Home/>
            <Product/>
            <Reason/>
            <WhitePaper/>
            <Token/>
            <Partner />
            <Questions/>
            <Team/>
            <Relations/>
            <News/>
            <Footer/>
            <Back/>
        </div>
    );
}

export default App
