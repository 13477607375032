const team = {
    "Milan Jandik": {
        "role": "CEO (Founder)",
        "department": "Team Lead",
        "image": require("./milan.png"),
        "linkedInURL": "https://www.linkedin.com/in/milan-jandik/"
    },
    "Oktay Hocaoglu": {
        "role": "CCO",
        "department": "Management and Compliance (AML, KYC, Security & Legal)",
        "image": require("./oktay.png"),
        "linkedInURL": "https://www.linkedin.com/in/oktay-hocaoglu-68ba32108/"
    },
    "Achuth Krishna": {
        "role": "CFO",
        "department": "Blockchain and Investment Expert",
        "image": require("./achuth.png"),
        "linkedInURL": "https://www.linkedin.com/in/achuth-k-chandran-b17880102/"
    },
    "Gokar Nier": {
        "role": "Chief Creative Officer",
        "department": "Head of Design",
        "image": require("../placeholder.png"),
        "linkedInURL": ""
    },
    "Zachary Cogan": {
        "role": "Business Developer",
        "department": "Partnerships and Relations",
        "image": require("./zachary.png"),
        "linkedInURL": "https://www.linkedin.com/in/zachary-cogan-721974a9/"
    },
    "Yuval Ardenbaum": {
        "role": "Business Development",
        "department": "Partnerships & Growth",
        "image": require("./yuval.png"),
        "linkedInURL": "https://www.linkedin.com/in/yuval-ardenbaum/"
    },
}

export default team
