import {Component} from "react"
import Section from "./Section"
import moment from "moment"
import {SubTitle} from "./Typing"

function ShowBlog({title, description, pubDate, link}) {
    const tag = document.createElement("div");
    tag.innerHTML = description

    const limitDescription = (rawDescription) => {
        let words = []
        let charCount = 0

        for (const word of rawDescription.split(" ")) {
            if (charCount + word.length > 420) {
                words.push("...")
                break
            } else {
                words.push(word)
                charCount += word.length
            }
        }

        return words.join(" ")
    }

    const displayableDescription = limitDescription(tag.innerText)
    const thumbnail = tag.querySelector("figure img").src

    return (
        <div className="relative flex flex-col rounded-xl overflow-hidden 2xl:max-w-md">
            <div className="h-60 bg-cover bg-center" style={{backgroundImage: `url(${thumbnail})`}}/>

            <div className="flex-1 flex flex-col justify-between gap-4 p-4 bg-neutral-100">
                <div className="flex flex-col gap-2">
                    <p className="text-md font-medium">
                        {title}
                    </p>

                    <p className="text-sm text-ellipsis">
                        {displayableDescription}
                    </p>
                </div>

                <p className="flex justify-between text-muted text-sm font-medium">
                    <a href={link} target="_blank" rel="noopener noreferrer"
                       className="text-md text-blue-600 font-medium hover:text-blue-700">
                        Read More
                    </a>
                    {moment(pubDate).format("MMM DD, YYYY hh:mm")}
                </p>
            </div>
        </div>
    );
}

export default class News extends Component {
    constructor(props) {
        super(props);

        this.mediumURL = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@frictionless-network"

        this.state = {
            items: [],
        };
    }

    async componentDidMount() {
        const response = await fetch(this.mediumURL, {mode: "cors"})
        const data = await response.json()
        const posts = data.items.filter(item => item.categories.length > 0)
        this.setState({items: posts.slice(0, 4)})
    }

    render() {
        return (
            <Section id="news">
                <SubTitle className="mb-20">
                    News
                </SubTitle>

                <div className="grid grid-cols-1 justify-center gap-4 sm:grid-cols-2 2xl:grid-cols-4">
                    {this.state.items.map((post, index) => (
                        <ShowBlog key={`blog_${index}`} {...post} {...this.state.profile} />
                    ))}
                </div>
            </Section>
        )
    }
}
